<template>
  <div class="login-container">
    <div class="login-section-login">
      <div class="login-container-left" 
        v-bind:style="{
          'background-image': 'url(' + image + ')'
        }"
      ></div>
      <div class="login-container-right">
        <h1 class="login-text">Log In</h1>
        <!-- <div class="login-btn-google" @click="googleLogin()"> -->
        <div class="login-btn-google" @click="handleClickSignIn">
          <img
            alt="image"
            src="/playground_assets/google.svg"
            class="login-image"
          />
          <span class="login-text1">Sign in with Google</span>
        </div>
        <div class="login-btn-facebook" @click="logInWithFacebook()">
          <img
            alt="image"
            src="/playground_assets/facebook-square.svg"
            class="login-image1"
          />
          <span class="login-text2">Sign in with Facebook</span>
        </div>
        <div class="login-container-label">
          <div class="login-line"></div>
          <span class="login-text3">or login with email</span>
          <div class="login-line1"></div>
        </div>
        <form class="w-full" @submit.prevent="login">
          <input-box v-model="email"></input-box>
          <input-box
            textinputPlaceholder="min. 8 characters"
            text="Password"
            type="password"
            v-model="password"
          ></input-box>
          <span @click="togglePassword()" class="login-forgot-password"
            >Forgot Password?</span
          >
          <div class="login-remember-me">
            <input type="checkbox" class="login-checkbox" />
            <span class="login-text4">Remember me for next login</span>
          </div>
          <XClose v-if="isModalError" :text="error_msg" />
          <button type="submit" class="login-btn-login" @click="login()">
            <span class="login-text5" v-if="!isLoading">Login</span>
            <svg
              version="1.1"
              v-if="isLoading"
              id="loader-1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              width="40px"
              height="40px"
              viewBox="0 0 40 40"
              enable-background="new 0 0 40 40"
              xml:space="preserve"
            >
              <path
                opacity="0.2"
                fill="#fff"
                d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946
              s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634
              c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"
              />
              <path
                fill="#fff"
                d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0
              C22.32,8.481,24.301,9.057,26.013,10.047z"
              >
                <animateTransform
                  attributeType="xml"
                  attributeName="transform"
                  type="rotate"
                  from="0 20 20"
                  to="360 20 20"
                  dur="0.5s"
                  repeatCount="indefinite"
                />
              </path>
            </svg>
          </button>
        </form>
        <div @click="goPage('/register')" class="login-register-label">
          <span class="login-text6">Don’t have an account?</span>
          <span class="login-text7">Register</span>
        </div>
      </div>
    </div>
    <Footer />
    <ModalPassword v-if="isPassword" />
  </div>
</template>

<script>
  import InputBox from '../components/input-box';
  import ModalPassword from '../components/modal-password';
  import Footer from '../components/footer';
  import XClose from '../components/x-close';
  import Api from '../api';
  import axios from 'axios';

  export default {
    name: 'Login',
    props: {},
    data() {
      return {
        isPassword: false,
        email: '',
        password: '',
        error_msg: '',
        isModalError: false,
        isLoading: false,
        signInFB: true,
        image: [],
      };
    },
    async created() {
      this.$root.$on('onClose', () => {
        this.isPassword = !this.isPassword;
      });
      this.$root.$on('onCloseError', () => {
        this.isModalError = !this.isModalError;
      });

      await this.getImage();
    },
    components: {
      InputBox,
      ModalPassword,
      Footer,
      XClose,
    },
    methods: {
      async handleClickSignIn() {
        try {
          const googleUser = await this.$gAuth.signIn();
          if (!googleUser) {
            return null;
          }
          this.isSignIn = this.$gAuth.isAuthorized;
          const body = {
            email: googleUser.getBasicProfile().getEmail(),
            name: googleUser.getBasicProfile().getName(),
          };
          const content = await axios.post(`${Api.googleLoginUrl}`, body);
          if (content.data.success) {
            const token = content.data.data.token;
            localStorage.setItem('token', JSON.stringify(token));
            const user = await axios.get(Api.profileUrl);
            if (user.data.status) {
              this.$store.commit('setUser', {
                ...user.data.data.user,
                token,
              });
              this.$store.commit('isAuthenticated', {
                isAuthenticated: true,
              });
              this.redirectUrl();
            } else {
              this.error_msg = user.message;
              this.isModalError = true;
            }
          }
        } catch (error) {
          //on fail do something
          return null;
        }
      },
      async logInWithFacebook() {
        await this.loadFacebookSDK(document, 'script', 'facebook-jssdk');
        await this.initFacebook();
        const login = () => {
          window.FB.login(
            (response) => {
              if (response.authResponse) {
                window.FB.api(
                  `/${response.authResponse.userID}/`,
                  {
                    fields: 'id, name, email',
                  },
                  async (response) => {
                    // console.log(response);
                    const body = {
                      fb_id: response.id,
                      name: response.name,
                      email: response.email,
                    };
                    const content = await axios.post(
                      `${Api.facebookLoginUrl}`,
                      body
                    );
                    if (content.data.success) {
                      const token = content.data.data.token;
                      localStorage.setItem('token', JSON.stringify(token));
                      const user = await axios.get(Api.profileUrl, {
                        headers: {
                          Authorization: `Bearer ${token}`,
                        },
                      });
                      if (user.data.status) {
                        this.$store.commit('setUser', {
                          ...user.data.data.user,
                          token,
                        });
                        this.$store.commit('isAuthenticated', {
                          isAuthenticated: true,
                        });
                        this.redirectUrl();
                      } else {
                        this.error_msg = user.message;
                        this.isModalError = true;
                      }
                    }
                  }
                );
              } else {
                console.log('User cancelled login or did not fully authorize.');
              }
            },
            {
              scope: 'email',
            }
          );
        };
        setInterval(() => {
          if (window.FB) {
            if (this.signInFB) {
              this.signInFB = false;
              login();
            }
          }
        }, 1000);
        return false;
      },
      async initFacebook() {
        window.fbAsyncInit = function () {
          window.FB.init({
            appId: process.env.VUE_APP_FB_ID, //You will need to change this
            cookie: true, // This is important, it's not enabled by default
            version: 'v12.0',
          });
        };
      },
      async loadFacebookSDK(d, s, id) {
        var js,
          fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
          return;
        }
        js = d.createElement(s);
        js.id = id;
        js.async = true;
        js.defer = true;
        js.crossorigin = 'anonymous';
        js.src = 'https://connect.facebook.net/en_US/sdk.js';
        fjs.parentNode.insertBefore(js, fjs);
      },
      login() {
        this.isLoading = true;
        const params = {
          email: this.email,
          password: this.password,
        };
        if (this.email === '' || this.password === '') {
          this.error_msg = 'Please fill in all fields';
          this.isModalError = true;
          this.isLoading = false;
          return;
        } else {
          this.$auth
            .login(params)
            .then(async (res) => {
              if (res.data.status === 'error') {
                const { errors } = res.data;
                this.error_msg = errors[Object.keys(errors)[0]][0];
                this.isModalError = true;
              } else {
                const { token } = res.data.data;

                localStorage.setItem('token', JSON.stringify(token));

                const userProfile = await axios.get(Api.profileUrl, {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                });
                this.$store.commit('setUser', {
                  ...userProfile.data.data.user,
                  token,
                });
                this.$store.commit('isAuthenticated', {
                  isAuthenticated: true,
                });
                this.redirectUrl();
              }
            })
            .catch((e) => {
              this.error_msg = e.response.data.message;
              this.isModalError = true;
            })
            .finally(() => {
              this.isLoading = false;
            });
        }
      },
      goPage(page) {
        this.$router.push(page);
      },
      togglePassword() {
        this.isPassword = !this.isPassword;
      },
      redirectUrl() {
        const tempUrl = localStorage.getItem('redirect_url');
        if (tempUrl) {
          this.$router.push(tempUrl).catch(() => {});
        } else {
          this.$router.push('/').catch(() => {});
        }
      },
      async getImage() {
        const rawResponse = await fetch(`${Api.baseUrl}/homepage/bannerlogin`, {
          method: 'GET',
        });
        const content = await rawResponse.json();
        if (content.status) {
          this.image = content.data.login[0].image;
        }
      },
    },
    watch: {
      isPassword: function () {
        if (this.isPassword) {
          document.documentElement.style.overflow = 'hidden';
          return;
        }

        document.documentElement.style.overflow = 'auto';
      },
    },
  };
</script>

<style scoped>
  .login-container {
    width: 100%;
    height: auto;
    display: flex;
    overflow: auto;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
  }
  .login-section-login {
    width: 100%;
    display: flex;
    align-items: flex-start;
  }
  .login-container-left {
    flex: 55%;
    width: 200px;
    height: 960px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    background-size: cover;
    background-image: url('/playground_assets/login_bg.jpg');
  }
  .login-container-right {
    flex: 45%;
    width: 200px;
    height: 960px;
    display: flex;
    align-items: flex-start;
    padding-top: 120px;
    /* padding-left: 70px;
  padding-right: 140px; */
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-twounits);
    justify-content: flex-start;
    background-color: #eae3d9;
  }
  .login-text {
    font-size: 3em;
    align-self: center;
    font-style: normal;
    font-family: Lora;
    font-weight: 700;
    margin-bottom: 24px;
  }
  .login-btn-google {
    flex: 0 0 auto;
    width: 100%;
    cursor: pointer;
    height: 64px;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-start;
    background-color: #ea4335;
  }
  .login-image {
    height: 24px;
    object-fit: cover;
    margin-left: var(--dl-space-space-unit);
  }
  .login-text1 {
    color: #fff;
    width: 100%;
    font-size: 18px;
    font-style: normal;
    text-align: center;
    font-family: Lora;
    font-weight: 700;
  }
  .login-btn-facebook {
    flex: 0 0 auto;
    width: 100%;
    cursor: pointer;
    height: 64px;
    display: flex;
    position: relative;
    margin-top: var(--dl-space-space-unit);
    align-items: center;
    justify-content: flex-start;
    background-color: #3b5998;
  }
  .login-image1 {
    height: 24px;
    object-fit: cover;
    margin-left: var(--dl-space-space-unit);
  }
  .login-text2 {
    color: #fff;
    width: 100%;
    font-size: 18px;
    font-style: normal;
    text-align: center;
    font-family: Lora;
    font-weight: 700;
  }
  .login-container-label {
    flex: 0 0 auto;
    width: 100%;
    height: var(--dl-size-size-small);
    display: flex;
    margin-top: var(--dl-space-space-twounits);
    align-items: center;
    margin-bottom: var(--dl-space-space-twounits);
    justify-content: space-between;
  }
  .login-line {
    flex: 0 0 auto;
    width: 30%;
    display: flex;
    border-top: 1px solid #1f252c;
    align-items: flex-start;
  }
  .login-text3 {
    color: #1f252c;
    font-size: 16px;
    font-family: Montserrat;
  }
  .login-line1 {
    flex: 0 0 auto;
    width: 30%;
    display: flex;
    border-top: 1px solid #1f252c;
    align-items: flex-start;
  }
  .login-forgot-password {
    color: #6b89e8;
    width: 100%;
    font-size: 14px;
    text-align: right;
    font-family: Montserrat;
    cursor: pointer;
  }
  .login-remember-me {
    width: 100%;
    height: var(--dl-size-size-small);
    display: flex;
    align-items: center;
  }
  .login-checkbox {
    border-color: var(--dl-color-gray-black);
    border-width: 1px;
  }
  .login-text4 {
    font-size: 16px;
    font-family: Montserrat;
    margin-left: var(--dl-space-space-halfunit);
  }
  .login-btn-login {
    flex: 0 0 auto;
    width: 100%;
    cursor: pointer;
    height: 64px;
    display: flex;
    position: relative;
    margin-top: var(--dl-space-space-unit);
    align-items: center;
    justify-content: center;
    background-color: #1f252c;
  }
  .login-text5 {
    color: #fff;
    width: 100%;
    font-size: 18px;
    font-style: normal;
    text-align: center;
    font-family: Montserrat;
    font-weight: 700;
    letter-spacing: 2.4px;
  }
  .login-register-label {
    width: 100%;
    height: 79px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 16px;
    cursor: pointer;
  }
  .login-text6 {
    font-family: Montserrat;
  }
  .login-text7 {
    color: #6b89e8;
    font-family: Montserrat;
    margin-left: var(--dl-space-space-halfunit);
  }
  @media (min-width: 1025px) {
    .login-container-right {
      padding-left: 70px;
      padding-right: 140px;
    }
  }
  @media (max-width: 991px) {
    .login-container-left {
      display: none;
    }
  }
  @media (max-width: 479px) {
    .login-container-right {
      padding: var(--dl-space-space-unit);
      padding-top: 80px;
    }
    .login-line {
      width: 20%;
    }
    .login-line1 {
      width: 20%;
    }
    .login-container-label {
      margin-top: 16px;
      margin-bottom: 16px;
    }
  }
</style>
