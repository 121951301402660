<template>
  <div class="input-box-input-box">
    <span v-if="label" class="input-box-text">{{ text }}</span>
    <input
      :disabled="disabled"
      :type="type"
      :placeholder="textinputPlaceholder"
      v-bind:value="value"
      v-on:input="$emit('input', $event.target.value)"
      :class="[
        { 'input-box-textinput': inputstyle == 'a' },
        { 'input-box-textinput-style-b': inputstyle == 'b' },
        'input',
      ]"
      @click="$emit('emitClick')"
    />
  </div>
</template>

<script>
export default {
  name: "InputBox",
  props: {
    label: {
      type: Boolean,
      default: true,
    },
    inputstyle: {
      type: String,
      default: "a",
    },
    textinputPlaceholder: {
      type: String,
      default: "ex. example@gmail.com",
    },
    text: {
      type: String,
      default: "Email​",
    },
    value: {
      type: String,
    },
    type: {
      type: String,
      default: "text",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
input:disabled {
  background-color: lightgrey;
  color: grey;
}
.input-box-input-box {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
  flex-direction: column;
}
.input-box-text {
  font-size: 18px;
  line-height: 24px;
  font-style: normal;
  font-family: Lora;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-halfunit);
  color: #1f252c;
}
.input-box-textinput {
  width: 100%;
  height: 64px;
  font-size: 16px;
  font-family: Montserrat;
  border: 1px solid #1f252c;
}
.input-box-textinput-style-b {
  width: 100%;
  font-size: 16px;
  font-family: Montserrat;
  border: 1px solid #eae3d9;
  padding: 10px 16px;
}
</style>
