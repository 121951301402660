<template>
  <div class="x-alert-container">
    <div class="x-alert-btn-login">
      <span class="x-alert-text">{{ text }}</span>
      <img
        @click="closeModal()"
        :src="image_src"
        :alt="image_alt"
        class="x-alert-image"
      />
    </div>
  </div>
</template>

<script>
  export default {
    name: 'XSukses',
    props: {
      text: {
        type: String,
        default: 'message',
      },
      image_src: {
        type: String,
        default: '/playground_assets/close_red.svg',
      },
      image_alt: {
        type: String,
        default: 'image',
      },
    },
    methods: {
      closeModal() {
        this.$root.$emit('onCloseSukses');
      },
    },
  };
</script>

<style scoped>
  .x-alert-container {
    width: 100%;
    display: flex;
    position: relative;
    align-items: flex-start;
    flex-direction: column;
    margin-top: 16px;
    background: #fff;
  }
  .x-alert-btn-login {
    flex: 0 0 auto;
    width: 100%;
    border: 1px solid rgb(59, 146, 78);
    cursor: pointer;
    display: flex;
    position: relative;
    align-items: center;
    padding: var(--dl-space-space-unit);
    justify-content: space-between;
  }
  .x-alert-text {
    color: rgb(59, 146, 78);
    font-size: 14px;
    font-style: normal;
    text-align: left;
    font-family: Montserrat;
    padding-right: 16px;
    letter-spacing: 2.4px;
  }
  .x-alert-image {
    width: 24px;
    object-fit: cover;
  }
</style>
