<template>
  <div class="modal-password-container" @click.self="closeModal()">
    <div class="modal-password-modal-wrap">
      <div class="modal-password-modal-header">
        <span class="modal-password-text">{{ title }}</span>
        <img @click="closeModal()" :src="image_src1" :alt="image_alt1" class="modal-password-image" />
      </div>
      <span class="modal-password-text1">{{ desc }}</span>
      <input-box v-model="email" rootClassName="rootClassName"></input-box>
      <XClose v-if="isModalError" :text="msg" />
      <XSukses v-if="isModalSukses" :text="msg" />
      <div class="modal-password-btn-login" @click="resetPassword()">
        <span class="modal-password-text2">{{ text }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import InputBox from './input-box'
import XClose from "../components/x-close";
import XSukses from "../components/x-sukses";
import Api from "../api";

export default {
  name: 'ModalPassword',
  data: function () {
    return {
      email: '',
      isModalError: false,
      isModalSukses: false,
      msg: '',
    };
  },
  props: {
    title: {
      type: String,
      default: 'Forgot Password',
    },
    image_src1: {
      type: String,
      default: '/playground_assets/x_close.svg',
    },
    image_alt1: {
      type: String,
      default: 'image',
    },
    desc: {
      type: String,
      default:
        'Please input your email below. We will send the reset password link to your email.',
    },
    text: {
      type: String,
      default: 'Reset Password',
    },
    // onClose: { type: Function },
  },
  components: {
    InputBox,
    XClose,
    XSukses
  },
   created() {
    this.$root.$on("onCloseError", () => {
      this.isModalError = !this.isModalError;
    });
    this.$root.$on("onCloseSukses", () => {
      this.isModalSukses = !this.isModalSukses;
    });
  },
  methods: {
    closeModal(){
      this.$root.$emit('onClose');
    },
    async resetPassword(){
      try {
        const params = {
          email: this.email,
        }
        this.isModalSukses = false;
        this.isModalError = false;
        const response = await this.$http.post(
          Api.forgotPasswordUrl, params
        );
        if(response.status===200){
            this.isModalSukses = true;
            this.msg = response.data.message;
        }else{
            this.isModalError = true;
            this.msg = response.data.message;
        }
      } catch (error) {
        console.log(error);
      }
    }
  }
}
</script>

<style scoped>
.modal-password-container {
  width: 100%;
  height: 100vh;
  display: flex;
  z-index: 100;
  position: fixed;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
}
.modal-password-modal-wrap {
  flex: 0 0 auto;
  width: 500px;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  align-items: flex-start;
  flex-direction: column;
  background-color: #fff;
  z-index: 101;
}
.modal-password-modal-header {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: space-between;
}
.modal-password-text {
  font-size: 2em;
  font-style: normal;
  font-family: Lora;
  font-weight: 700;
}
.modal-password-image {
  width: 46px;
  object-fit: cover;
}
.modal-password-text1 {
  font-family: Montserrat;
  line-height: 2;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.modal-password-btn-login {
  flex: 0 0 auto;
  width: 100%;
  cursor: pointer;
  height: 64px;
  display: flex;
  position: relative;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  justify-content: flex-start;
  background-color: #1F252C;
}
.modal-password-text2 {
  color: #fff;
  width: 100%;
  font-size: 18px;
  font-style: normal;
  text-align: center;
  font-family: Montserrat;
  font-weight: 700;
  letter-spacing: 2.4px;
}
@media(max-width: 991px) {
  .modal-password-modal-wrap {
    width: 100%;
    height: auto;
  }
}
@media(max-width: 479px) {
  .modal-password-modal-wrap {
    width: 100%;
  }
}
</style>
